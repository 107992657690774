// Styles
// CoreUI Icons Set
// @import '~@coreui/icons/css/free.min.css';
// Import Flag Icons Set
// @import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
// @import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import "~bootstrap/scss/bootstrap";
@import './scss/style.scss';


.table-hover tbody tr:hover {
    background-color: #f8f9fa;
}
.table-hover tbody tr:hover {
    background-color: #f8f9fa;
}
